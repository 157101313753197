import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Spinner,
  ModalBody,
  Col,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText,
  Label,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import { getUserAction } from "store/actions/authAction";
import { blockUserAction } from "store/actions/authAction";
import { createAdminAction } from "store/actions/authAction";
import { getAdminUserAction } from "store/actions/authAction";
import { deleteAdminAction } from "store/actions/authAction";
import { MdDelete } from "react-icons/md";
import { RxPencil2 } from "react-icons/rx";
import Select from "react-select";
import { getTicketsAction } from "store/actions/ticketAction";
import { assignTicketAction } from "store/actions/ticketAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Tickets = () => {
  const dispatch = useDispatch();

  const [assignModal, setAssignModal] = useState(false);
  const [id, setId] = useState();
  const [adminId, setAdminId] = useState(null);
  const [filterTicket, setFilterTicket] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  console.log("adminid", adminId);

  const assignToggle = () => {
    setAssignModal(!assignModal);
  };
  const { adminUsers, loading, blockLoading, getLoader } = useSelector(
    (state) => state.userData
  );
  const { ticketsData } = useSelector((state) => state.ticket);

  useEffect(() => {
    dispatch(getAdminUserAction());
    dispatch(getTicketsAction());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (adminId != null) {
      let payload = {
        TicketId: id,
        AdminId: adminId,
      };
      dispatch(
        assignTicketAction(payload, () => {
          assignToggle();
          setAdminId("");
          setId("");
          dispatch(getTicketsAction());
        })
      );
    } else {
      alert("Select Admin");
    }
  };
  const handleSelectChange = (selectedOption) => {
    setAdminId(selectedOption?.value);
  };
  let adminOption = useMemo(() => {
    if (adminUsers.length > 0) {
      return adminUsers?.map((element) => ({
        label: element.Email,
        value: element?.Id,
      }));
    } else {
      return [];
    }
  }, [adminUsers]);

  useEffect(() => {
    if (ticketsData.length > 0) {
      setFilterTicket(ticketsData);
    } else {
      setFilterTicket([]);
    }
  }, [ticketsData]);

  const handleChange = (e) => {
    let data = ticketsData.filter((obj) => {
      return (
        obj.Status.includes(e.target.value) ||
        obj.Title.includes(e.target.value) ||
        obj.TicketId.includes(e.target.value) ||
        obj.Email.includes(e.target.value)
      );
    });
    setFilterTicket(data);
  };
  const history = useHistory();
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-0">Assign Tickets </div>
                </div>
              </CardHeader>

              <Row className="px-2 py-2">
                <Col className="d-flex align-items-center">
                  <div>
                    <Input
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        handleChange(e);
                        setSearch(e.target.value);
                      }}
                    ></Input>
                  </div>
                  <div className="w-25 mx-2">
                    <Input
                      type="select"
                      value={status}
                      onChange={(e) => {
                        handleChange(e);
                        setStatus(e.target.value);
                      }}
                    >
                      <option value="">Select Status</option>
                      <option value="pending">pending</option>
                      <option value="In Progress">In Progress</option>
                    </Input>
                  </div>
                </Col>
              </Row>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Ticket Id</th>
                    <th scope="col">Ticket Tilte</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {getLoader ? (
                    <>
                      <tr>
                        <td colspan="4">
                          <div className="mt-2 d-flex align-items-center  py-4 justify-content-center">
                            <div>
                              <Spinner />
                            </div>
                          </div>{" "}
                        </td>

                        <td></td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {filterTicket?.map((obj) => (
                        <>
                          <tr>
                            <td>{obj?.TicketId}</td>
                            <td>{obj?.Title}</td>
                            <td>{obj?.Email}</td>
                            <td>{obj?.Status}</td>
                            <td>
                              {obj?.Status == "pending" ? (
                                <>
                                  <Button
                                    className="bg-gradient-info cursor-pointer text-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      assignToggle();
                                      setId(obj?.Id);
                                    }}
                                  >
                                    Assign Ticket
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  className="bg-gradient-info cursor-pointer text-white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    history.push({
                                      pathname: `/super_admin/assign-tickets/chat_show/${obj?.TicketId}`,
                                      state: obj,
                                    });
                                  }}
                                >
                                  Check Chat
                                </Button>
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}

                  {ticketsData?.length == 0 && (
                    <>
                      <tr>
                        <td colspan="4">
                          {" "}
                          <div className="mt-2  d-flex align-items-center py-4 justify-content-center w-full">
                            <div>No Record Found</div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>

      {/* create  Admin  */}
      <Modal
        isOpen={assignModal}
        toggle={assignToggle}
        size="md"
        centered={true}
        className="modal"
      >
        <div className="bg-gradient-info modal__header d-flex justify-content-between align-items-center px-4 py-4">
          <div className="fs--18 fw--600">Assign Ticket</div>
          <div className="cursor-pointer" onClick={assignToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                fill="#ffff"
                fill-opacity="0.4"
              />
              <path
                d="M9.10205 14.7568L14.8977 9.24228"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <ModalBody className="px-4 py-5">
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <Label>Select Admin</Label>
              <Select options={adminOption} onChange={handleSelectChange} />
            </FormGroup>
            <Row className="mt-4">
              <Col>
                <div className="text-right">
                  <Button
                    className="bg-gradient-info program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? <Spinner size="sm" /> : `${"Save"}`}
                  </Button>
                  <Button
                    className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                    onClick={(e) => {
                      e.preventDefault();
                      assignToggle();
                      adminId("");
                      id("");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
export default Tickets;

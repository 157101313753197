import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Spinner,
  ModalBody,
  Col,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { getUserAction } from "store/actions/authAction";
import { blockUserAction } from "store/actions/authAction";
import { createAdminAction } from "store/actions/authAction";
import { getAdminUserAction } from "store/actions/authAction";
import { deleteAdminAction } from "store/actions/authAction";
import { MdDelete } from "react-icons/md";
import { RxPencil2 } from "react-icons/rx";

const AdminUser = () => {
  const dispatch = useDispatch();
  const [block, setBlock] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [id, setId] = useState();
  const [data, setData] = useState({
    Email: "",
    Password: "",
  });
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const blockToggle = () => {
    setBlock(!block);
  };
  const toggle = () => {
    setAddModal(!addModal);
  };
  const { adminUsers, loading, blockLoading, getLoader } = useSelector(
    (state) => state.userData
  );

  useEffect(() => {
    dispatch(getAdminUserAction());
  }, []);
  const handleBlocked = () => {
    dispatch(
      deleteAdminAction(id, () => {
        blockToggle();
        setId("");
        dispatch(getAdminUserAction());
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createAdminAction(data, () => {
        toggle();
        dispatch(getAdminUserAction());
        setData({
          Email: "",
          Password: "",
        });
      })
    );
  };

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-0">Admins</div>
                  <div className="mb-0">
                    <Button
                      className="bg-gradient-info cursor-pointer text-white"
                      onClick={toggle}
                    >
                      Create Admin
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {getLoader ? (
                    <>
                      <tr>
                        <td colspan="3">
                          <div className="mt-2 d-flex align-items-center  py-4 justify-content-center">
                            <div>
                              <Spinner />
                            </div>
                          </div>{" "}
                        </td>

                        <td></td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {adminUsers?.map((obj) => (
                        <>
                          <tr>
                            <td>{obj?.UserName}</td>
                            <td>{obj?.Email}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                {/* <div
                                  className="cursor-pointer mr-3"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    blockToggle();
                                    setId(obj?.Id);
                                  }}
                                >
                                  <RxPencil2
                                    className="text-primary cursor-pointer"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "600",
                                    }}
                                  />
                                </div> */}

                                <div
                                  className="cursor-pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    blockToggle();
                                    setId(obj?.Id);
                                  }}
                                >
                                  <MdDelete
                                    className="text-danger cursor-pointer"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "600",
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}

                  {adminUsers?.length == 0 && (
                    <>
                      <tr>
                        <td colspan="3">
                          {" "}
                          <div className="mt-2  d-flex align-items-center py-4 justify-content-center w-full">
                            <div>No Record Found</div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>

      {/* create  Admin  */}
      <Modal
        isOpen={addModal}
        toggle={toggle}
        size="md"
        centered={true}
        className="modal"
      >
        <div className="bg-gradient-info modal__header d-flex justify-content-between align-items-center px-4 py-4">
          <div className="fs--18 fw--600">Create Admin</div>
          <div className="cursor-pointer" onClick={toggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                fill="#ffff"
                fill-opacity="0.4"
              />
              <path
                d="M9.10205 14.7568L14.8977 9.24228"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <ModalBody className="px-4 py-5">
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="Email"
                  value={data.Email}
                  name="Email"
                  required
                  onChange={handleChange}
                  autoComplete="new-email"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  value={data.Password}
                  required
                  name="Password"
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </InputGroup>
            </FormGroup>

            {/* <Row>
            <Col>
              
            </Col>
          </Row> */}

            <Row className="mt-4">
              <Col>
                <div className="text-right">
                  <Button
                    className="bg-gradient-info program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? <Spinner size="sm" /> : `${"Add"}`}
                  </Button>
                  <Button
                    className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                    onClick={(e) => {
                      e.preventDefault();
                      toggle();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* Delete Admin */}
      <Modal
        isOpen={block}
        toggle={blockToggle}
        size="md"
        centered={true}
        className="modal"
      >
        <div className="bg-gradient-info modal__header d-flex justify-content-between align-items-center px-4 py-4">
          <div className="fs--18 fw--600">Delete Admin</div>
          <div className="cursor-pointer" onClick={blockToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                fill="#ffff"
                fill-opacity="0.4"
              />
              <path
                d="M9.10205 14.7568L14.8977 9.24228"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <ModalBody className="px-4 py-5">
          <Row>
            <Col>
              <div className="text-center fs--24 fw--600 text-dark  ">
                Are you sure you want to delete this admin?
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <div className="text-right">
                <Button
                  className="bg-gradient-info program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                  onClick={handleBlocked}
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : `${"Yes"}`}
                </Button>
                <Button
                  className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                  onClick={(e) => {
                    e.preventDefault();
                    blockToggle();
                    setId("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};
export default AdminUser;

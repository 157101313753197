import {
  BLOCK_LOADER,
  GET_ADMIN_USER,
  GET_LOADER,
  GET_USER,
  LOGIN,
  LOGIN_LOADER,
  LOGOUT,
} from "../types";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
var auth = RepositoryFactory.get("auth");

export const setAuthLoading = (val) => async (dispatch) => {
  dispatch({ type: LOGIN_LOADER, payload: val });
};

export const setGetLoading = (val) => async (dispatch) => {
  dispatch({ type: GET_LOADER, payload: val });
};
export const setBlockLoading = (val) => async (dispatch) => {
  dispatch({ type: BLOCK_LOADER, payload: val });
};

export const LoginAction = (payload, onSuccess) => async (dispatch) => {
  console.log("payload", payload);
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await auth.userLogin(payload);

    if (data?.detail?.UserId) {
      dispatch({ type: LOGIN, payload: data });
      onSuccess(data);
      await dispatch(setAuthLoading(false));
    } else {
      alert(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    alert(e.message);
    await dispatch(setAuthLoading(false));
    dispatch(setAuthLoading(false));
  }
};

export const createAdminAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await auth.createAdmin(payload);

    if (data?.status_code == 200) {
      alert("Create Admin Successfully");
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      alert(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    alert(e.message);
    await dispatch(setAuthLoading(false));
  }
};
export const updateAdminAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await auth.updateAdmin(payload);

    if (data?.status_Code == 200) {
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      alert(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    alert(e.message);
    await dispatch(setAuthLoading(false));
  }
};
export const deleteAdminAction = (id, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await auth.deleteAdmin(id);

    if (data?.status_code == 200) {
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      alert(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    alert(e.message);
    await dispatch(setAuthLoading(false));
  }
};
export const getAdminUserAction = () => async (dispatch) => {
  try {
    await dispatch(setGetLoading(true));
    const { data } = await auth.getAdmin();

    if (data?.status_code == 200) {
      dispatch({ type: GET_ADMIN_USER, payload: data?.detail });
      await dispatch(setGetLoading(false));
    } else {
      alert(data?.detail);
      dispatch({ type: GET_ADMIN_USER, payload: [] });
      await dispatch(setGetLoading(false));
    }
  } catch (error) {
    console.error("Error ", error?.message);
    await dispatch(setGetLoading(false));
  }
};

export const getUserAction = (page) => async (dispatch) => {
  try {
    await dispatch(setAuthLoading(true));
    const { data } = await auth.getUserByPage(page);
    console.log("uData", data);
    if (data?.users?.length > 0) {
      dispatch({ type: GET_USER, payload: data });
      await dispatch(setAuthLoading(false));
    } else {
      dispatch({ type: GET_USER, payload: {} });
      await dispatch(setAuthLoading(false));
    }
  } catch (error) {
    console.error("Error logging out:", error?.message);
    await dispatch(setAuthLoading(false));
  }
};

export const blockUserAction = (id, onSuccess) => async (dispatch) => {
  try {
    await dispatch(setBlockLoading(true));
    const { data } = await auth.blockUser(id);
    if (data?.Detail) {
      alert(data?.Detail);
      onSuccess();
      await dispatch(setBlockLoading(false));
    } else {
      alert(data?.detail);
      await dispatch(setBlockLoading(false));
    }
  } catch (error) {
    alert(error.message);
    await dispatch(setBlockLoading(false));
    console.error("Error logging out:", error?.message);
  }
};

export const logout = (onSuccess) => async (dispatch) => {
  localStorage.clear();
  onSuccess();
  dispatch({
    type: LOGOUT,
  });
};

import Repository from "./Repository";
const LOGIN_USER = "User/Login";
const GET_USER = "User/Get-All-Users";

const GET_USER_PAGE = "/User/Get-All-Users-By-Page-Number?PageNo=";
const GET_ADMIN_USER = "/User/Get-All-Admins";
const CREATE_ADMIN = "/User/Create-Admin";
const DELETE_ADMIN = "/User/Delete-Admin?AdminId=";
const UPDATE_ADMIN = "User/Admin";
const BLOCK_USER = "User/Block-User?UserId=";
export default {
  blockUser(id) {
    return Repository.post(`${BLOCK_USER}${id}`);
  },
  userLogin(obj) {
    return Repository.post(`${LOGIN_USER}`, obj);
  },
  createAdmin(obj) {
    return Repository.post(
      `${CREATE_ADMIN}?Email=${obj?.Email}&Password=${obj?.Password}`
    );
  },
  getAdmin() {
    return Repository.get(`${GET_ADMIN_USER}`);
  },
  updateAdmin(obj) {
    return Repository.put(`${UPDATE_ADMIN}`, obj);
  },
  deleteAdmin(id) {
    return Repository.delete(`${DELETE_ADMIN}${id}`);
  },
  getUserByPage(page) {
    return Repository.get(`${GET_USER_PAGE}${page}`);
  },
};

// import React from "react";
// import blogImg from "../assets/img/userchat.jpeg";
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   CardImg,
//   CardBody,
//   CardTitle,
//   CardText,
//   Button,
// } from "reactstrap";

// export default function CardComponent() {
//   const blogData = [
//     {
//       id: 1,
//       img: blogImg,
//       title: "Programming",
//       desc: "Description about your blog",
//       publish: "Publish",
//       delete: "Delete",
//     },
//     {
//       id: 2,
//       img: blogImg,
//       title: "Programming",
//       desc: "Description about your blog",
//       publish: "Publish",
//       delete: "Delete",
//     },
//     {
//       id: 3,
//       img: blogImg,
//       title: "Programming",
//       desc: "Description about your blog",
//       publish: "Publish",
//       delete: "Delete",
//     },
//   ];
//   return (
//     <Container>
//       <Row>
//         {blogData.map((blog) => (
//           <Col md={4} key={blog.id}>
//             <Card>
//               <CardImg height="300px" src={blog.img} />
//               <CardBody>
//                 <h1 tag="h5">{blog.title}</h1>
//                 <p>{blog.desc}</p>

//                 <div className="d-flex justify-content-between ">
//                   <Button className="bg-success text-white hover:cursor-pointer">
//                     {blog.publish}
//                   </Button>

//                   <Button className="bg-danger text-white cursor-pointer">
//                     {blog.delete}
//                   </Button>
//                 </div>
//               </CardBody>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   );
// }

import React, { useState } from "react";
import blogImg from "../assets/img/userchat.jpeg";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

export default function CardComponent() {
  const [blogData, setBlogData] = useState([
    {
      id: 1,
      img: blogImg,
      title: "Programming",
      desc: "Description about your blog",
    },
    {
      id: 2,
      img: blogImg,
      title: "Programming",
      desc: "Description about your blog",
    },
    {
      id: 3,
      img: blogImg,
      title: "Programming",
      desc: "Description about your blog",
    },
  ]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const toggleDeleteModal = (item) => {
    setItemToDelete(item);
    setDeleteModalOpen(!deleteModalOpen);
  };

  const handleDelete = () => {
    if (itemToDelete) {
      setBlogData(blogData.filter((item) => item.id !== itemToDelete.id));

      setDeleteModalOpen(false);
    }
  };

  return (
    <Container>
      <Row>
        {blogData.map((blog) => (
          <Col md={4} key={blog.id}>
            <Card>
              <CardImg height="300px" src={blog.img} />
              <CardBody>
                <h1 tag="h5">{blog.title}</h1>
                <p>{blog.desc}</p>

                <div className="d-flex justify-content-between">
                  <Button className="bg-success text-white hover:cursor-pointer">
                    Publish
                  </Button>

                  <Button
                    className="bg-danger text-white cursor-pointer"
                    onClick={() => toggleDeleteModal(blog)}
                  >
                    Delete
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      {/*This Modal is for Delete Confirmation */}
      <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>
          Delete Confirmation
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this item?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { SUBSCRIPTION } from "store/types";
import { SUBSCRIPTION_LOADER } from "store/types";
var subscription = RepositoryFactory.get("subscription");
export const setSubscriptionLoading = (val) => async (dispatch) => {
  dispatch({ type: SUBSCRIPTION_LOADER, payload: val });
};

export const getSubscriptionAction = () => async (dispatch) => {
  try {
    await dispatch(setSubscriptionLoading(true));
    const { data } = await subscription.getSubscriptionRep();
    console.log("df", data);

    if (data?.Detail?.length > 0) {
      dispatch({ type: SUBSCRIPTION, payload: data?.Detail });
      await dispatch(setSubscriptionLoading(false));
    } else {
      dispatch({ type: SUBSCRIPTION, payload: data?.Detail });
      await dispatch(setSubscriptionLoading(false));
    }
  } catch (error) {
    alert(error?.message);
    await dispatch(setSubscriptionLoading(false));
  }
};

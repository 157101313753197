
import { GET_SEARCH_NEWS } from "store/types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { NEWS_LOADER } from "store/types";
import { PUBLISH_LOADER } from "store/types";
import { GET_ALL_PUBLISH_NEWS } from 'store/types'
import { DELETE_NEWS } from 'store/types'
import {UPDATE_LOADER} from 'store/types'
import { data } from "jquery";
var news = RepositoryFactory.get('news');

export const setNewsLoader = (val) => async (dispatch) => {
    dispatch({ type: NEWS_LOADER, payload: val })
}
export const setPublishLoader = (val) => async (dispatch) => {
    dispatch({ type: PUBLISH_LOADER, payload: val })
}
export const updateNewsLoader = (val) => async (dispatch) => {
    dispatch({ type: UPDATE_LOADER, payload: val });
  };
//************************** THIS IS FOR THE GET NEWS [GET API.......]***************//

export const getNewsAction = (id) => async (dispatch) => {
    try {
        await dispatch(setNewsLoader(true))
        const { data } = await news.getSearchNewsRep(id)
        console.log(data, 'this is newsAction data..')
        if (data?.status_code == 200) {
            await dispatch(setNewsLoader(false))
            if (data.detail != "Error Fetching data from API") {
                dispatch({ type: GET_SEARCH_NEWS, payload: data.detail })
            } else {
                alert(data.detail)
                dispatch({ type: GET_SEARCH_NEWS, payload: null })
            }

        }
        else {
            alert(data?.detail);
            dispatch({ type: GET_SEARCH_NEWS, payload: null })
            await dispatch(setNewsLoader(false))

        }
    } catch (err) {
        alert(err)
        await dispatch(setNewsLoader(false))

    }

}
//**************************THIS IS FOR THE PUBLISH NEWS [POST API.......]***************
export const publishNews = (id) => async (dispatch) => {
    try {
        await dispatch(setPublishLoader(true))
        const { data } = await news.postPublishNews(id)
        if (data?.status_code == 200) {
            dispatch({ type: GET_SEARCH_NEWS, payload: null })

            await dispatch(setPublishLoader(false))
            alert(data?.detail)
        }
        else {
            alert(data?.detail)
            dispatch({ type: GET_SEARCH_NEWS, payload:null })

            await dispatch(setPublishLoader(false))
        }
    }
    catch (err) {
        alert(err)
        await dispatch(setPublishLoader(false))
    }

}

//**************************THIS IS FOR THE GET ALL PUBLISH NEWS [GET API.......]***************

export const allPublishNews = () => async (dispatch) => {
    try {
        await dispatch(setPublishLoader(true))

        const { data } = await news.getAllPublishNews()
        if (data?.status_code == 200) {
            dispatch({ type: GET_ALL_PUBLISH_NEWS, payload: data.detail })
            await dispatch(setPublishLoader(false))
        }
        else {
            alert(data?.detail)
            dispatch({ type: GET_ALL_PUBLISH_NEWS, payload: [] })
            await dispatch(setPublishLoader(false))
        }

    }
    catch (err) {
        alert(err)
        await dispatch(setPublishLoader(false))
    }
}
// **************** THIS IS FOR DELETE API[DELETE API]*********

export const deleteNews = (id, onSuccess) => async (dispatch) => {

    try {
        await dispatch(setNewsLoader(true))
        const { data } = await news.deleteAllNews(id)

        if (data?.status_code == 200) {
            await dispatch(setNewsLoader(false))
            alert(data?.detail)
            dispatch({ type: GET_SEARCH_NEWS, payload: null })
            onSuccess()

        }
        else {
            alert(data?.detail)
            await dispatch(setNewsLoader(false))

        }

    }
    catch (err) {
        alert(err)
        await dispatch(setNewsLoader(false))

    }
}

export const updatehNewsAction = (obj) => async (dispatch) => {
    try {
      await dispatch(updateNewsLoader(true));
      const { data } = await news.updateNewsRep(obj);
      if (data?.status_code == 200) {
        await dispatch(updateNewsLoader(false));
        alert(data?.detail);
      } else {
        alert(data?.detail);
        await dispatch(updateNewsLoader(false));
      }
    } catch (err) {
      alert(err);
      await dispatch(setNewsLoader(false));
    }
  };
export const LOGIN = "LOGIN";

export const LOGOUT = "LOGOUT";

export const PROGRAM_LOADER = "PROGRAM_LOADER";
export const LOGIN_LOADER = "LOGIN_LOADER";
export const WAIT_LIST = "WAIT_LIST";
export const SUBSCRIPTION = "SUBSCRIPTION";
export const WAIT_LIST_LOADER = "WAIT_LIST_LOADER";
export const TOTAL_DASHBOARD = "TOTAL_DASHBOARD";
export const SUBSCRIPTION_LOADER = "SUBSCRIPTION_LOADER";
export const GET_USER = "GET_USER";
export const STATUS_LOADER = "STATUS_LOADER";
export const BLOCK_LOADER = "BLOCK_LOADER";
export const GET_ADMIN_USER = "GET_ADMIN_USER";
export const GET_LOADER = "GET_LOADER";
export const GET_TICKETS = "GET_TICKETS";
export const GET_ADMIN_TICKETS = "GET_ADMIN_TICKETS";
export const GET_TICKETS_CHAT = "GET_TICKETS_CHAT";
export const CHAT_HISTORY = "CHAT_HISTORY";
export const CHAT_DETAIL = "CHAT_DETAIL";

export const GET_SEARCH_NEWS = "GET_SEARCH_NEWS";
export const NEWS_LOADER = "NEWS_LOADER";
export const PUBLISH_LOADER = "PUBLISH_LOADER";
export const GET_ALL_PUBLISH_NEWS = "GET_ALL_PUBLISH_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const UPDATE_LOADER = "UPDATE_LOADER";

import React, { useEffect, useRef, useState } from "react";
import { Col, Input, Row } from "reactstrap";
import { IoIosSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getTicketChatAction } from "store/actions/ticketAction";
import { ticketChatAction } from "store/actions/ticketAction";
import { DotPulse } from "@uiball/loaders";

function Chatting({ ticketId, email }) {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTicketChatAction(ticketId));
  }, [ticketId]);

  const { ticketChats } = useSelector((state) => state.ticket);
  const { user } = useSelector((state) => state.authUser);
  const { adminUsers, loading, blockLoading, getLoader } = useSelector(
    (state) => state.userData
  );
  const handleChatMessage = () => {
    let payload = {
      TicketId: ticketId,
      Role: user?.detail?.Role,
      Message: message,
    };
    dispatch(
      ticketChatAction(payload, () => {
        dispatch(getTicketChatAction(ticketId));
        setMessage("");
      })
    );
  };
  const chatContainerRef = useRef(null);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [ticketChats]);
  return (
    <>
      <Row className="bg-gradient-info  text-white py-3">
        <Col className="d-flex justify-content-between">
          <div className="text-white  fs--24">
            <span
              style={{
                fontSize: "24",
              }}
              className="mx-2"
            >
              Ticket Id:
            </span>{" "}
            <span>{ticketId}</span>
          </div>
          <div className="text-white fs--24">
            <span
              className="mx-2"
              style={{
                fontSize: "24",
              }}
            >
              Created by:
            </span>
            <span>{email}</span>
          </div>
        </Col>
      </Row>
      <Row
        className=" overflow-auto mb-3"
        style={{
          height: "400px",
        }}
      >
        <Col>
          <Row ref={chatContainerRef}>
            {ticketChats?.map((obj) => {
              if (obj?.Role == user?.detail?.Role) {
                return (
                  <>
                    <Col xs="12" className="justify-content-end my-2 py-2">
                      <div className="text-right">
                        <span className="bg-secondary text-black rounded p-2">
                          {obj?.Message}
                        </span>
                      </div>
                    </Col>
                  </>
                );
              } else {
                return (
                  <>
                    <Col xs="12" className="justify-content-start my-2 py-2 ">
                      <div className="text-left mt-2">
                        {user?.detail?.Role == "SuperAdmin" && (
                          <span className="bg-secondary  text-black mr-2 rounded p-2">
                            {obj?.Role}
                          </span>
                        )}

                        <span className="bg-primary text-white rounded p-2">
                          {obj?.Message}
                        </span>
                      </div>
                    </Col>
                  </>
                );
              }
            })}
          </Row>
        </Col>
      </Row>
      {user?.detail?.Role != "SuperAdmin" && (
        <Row className=" shadow py-2 rounded-md border px-2">
          <Col className="justify-content-between d-flex  align-items-center">
            <div className="ticket_chat w-100">
              <Input
                className="ticket_chat__input"
                placeholder="Send Message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></Input>
            </div>
            <div className="mx-2">
              {loading ? (
                <DotPulse size={25} speed={1.3} color="black" />
              ) : (
                <IoIosSend
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                  }}
                  className="text-primary cursor-pointer"
                  onClick={handleChatMessage}
                />
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Chatting;

import AuthUserRepository from "./AuthUserRepository";
import WaitListRepository from "./WaitListRepository";
import SubscriptionRepository from "./SubscriptionRepository";
import TicketRepository from "./TicketRepository";
import ChatRepository from "./ChatRepository";
import NewsRepository from "./NewsRepository";
const repositories = {
  auth: AuthUserRepository,
  waitList: WaitListRepository,
  subscription: SubscriptionRepository,
  ticket: TicketRepository,
  chatting: ChatRepository,
  news: NewsRepository,
};
export const RepositoryFactory = {
  get: (name) => repositories[name],
};

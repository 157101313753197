import axios from "axios";
const baseDomain = "https://backend.opentruth.ai/";
// const baseDomain = "http://192.168.18.106:8000";
const baseURL = `${baseDomain}`;
const axiosObj = axios.create({
  baseURL,
});
axiosObj.interceptors.request.use(
  (config) => {
    // Dynamically set the token for each request
    const ls = JSON.parse(localStorage.getItem("authUser"));
    const token = ls?.token || "";
    config.headers.token = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosObj;

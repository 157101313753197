export const RichTextViewer = ({ text }) => {
    const renderParagraph = (paragraph) => {
      const regex = /(\*\*(.*?)\*\*)|(\[([^\[]+)\]\((.*?)\))|([^*[]+)/g;
      let parts = paragraph.match(regex);

      return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          // Handling bold text
          return <strong key={index}>{part.slice(2, -2)}</strong>;
        } else if (part.startsWith('[') && part.includes('](')) {
          // Handling markdown links
          const match = part.match(/\[([^\[]+)\]\((.*?)\)/);
          const linkText = match[1];
          const url = match[2];
          return <a key={index} href={url} target="_blank" rel="noopener noreferrer">{linkText}</a>;
        } else {
          // Handling regular text
          return <span key={index}>{part}</span>;
        }
      });
    };
     const renderText = () => {
    // Splitting the text into paragraphs
    const paragraphs = text?.split('\n\n');

    return paragraphs?.map((paragraph, index) => (
      <p key={index}>{renderParagraph(paragraph)}</p>
    ));
  };

  return <div>{renderText()}</div>;

}
import {
  STATUS_LOADER,
  TOTAL_DASHBOARD,
  WAIT_LIST,
  WAIT_LIST_LOADER,
} from "../types";

const initialState = {
  waitListData: [],
  loading: false,
  statusLoading: false,
  totalDashboard: [],
};

export default function waitListReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case WAIT_LIST:
      return {
        ...state,
        waitListData: payload,
      };
    case TOTAL_DASHBOARD:
      return {
        ...state,
        totalDashboard: payload,
      };
    case WAIT_LIST_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case STATUS_LOADER:
      return {
        ...state,
        statusLoading: payload,
      };
    default:
      return {
        ...state,
      };
  }
}

import Repository from "./Repository";
const GET_TICKETS = "/Ticket/Get-All-Tickets";
const GET_TICKET_CHAT = "/Ticket/Get-Ticket-Chat?TicketId=";
const TICKET_CHAT = "/Ticket/Ticket-Chat";
const ASSIGN_TICKET = "/Ticket/Assign-Ticket?AdminId=";
const ADMIN_TICKETS = "/Ticket/Get-Admin-Tickets?AdminId=";

export default {
  getTicketsRep() {
    return Repository.get(`${GET_TICKETS}`);
  },
  getTicketChatRep(id) {
    return Repository.get(`${GET_TICKET_CHAT}${id}`);
  },
  ticketChatRep(obj) {
    return Repository.post(`${TICKET_CHAT}`, obj);
  },
  assignTicketRep(obj) {
    return Repository.post(
      `${ASSIGN_TICKET}${obj?.AdminId}&TicketId=${obj.TicketId}`
    );
  },
  getAdminTicketRep(id) {
    return Repository.get(`${ADMIN_TICKETS}${id}`);
  },
};

import { GET_ADMIN_TICKETS, GET_TICKETS, GET_TICKETS_CHAT } from "../types";

const initialState = {
  ticketsData: [],
  adminTickets: [],
  ticketChats: [],
};
export default function ticketReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TICKETS:
      return {
        ...state,
        ticketsData: payload,
      };
    case GET_ADMIN_TICKETS:
      return {
        ...state,
        adminTickets: payload,
      };
    case GET_TICKETS_CHAT:
      return {
        ...state,
        ticketChats: payload,
      };
    default:
      return {
        ...state,
      };
  }
}

import Repository from "./Repository";
const GET_WAITLIST = "WaitList/Get-Waitlist-Data";
const DASHBOARD = "/User/Get-Total-No-Of-Users-Memeberships-Waitlist";
const CHANGE_STATUS = "WaitList/Change-WaitList-User-Status?WaitlistId=";
const DELETE_WAITLIST = "WaitList/Delete-Waitlist-User?WaitlistId=";
export default {
  getWaitListRep() {
    return Repository.get(`${GET_WAITLIST}`);
  },
  getTotalRep() {
    return Repository.get(`${DASHBOARD}`);
  },
  deleteWaitListRep(id) {
    return Repository.delete(`${DELETE_WAITLIST}${id}`);
  },
  changeStatusRep(payload) {
    return Repository.post(
      `${CHANGE_STATUS}${payload?.Id}&Status=${payload?.status}`
    );
  },
};

// import { toast } from "react-toastify";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { CHAT_HISTORY, CHAT_DETAIL } from "../types";
import { baseURL } from "../../repository/Repository";
var chatting = RepositoryFactory.get("chatting");
export const chatHistoryAction = (email, onSuccess) => async (dispatch) => {
  try {
    const { data } = await chatting.historyChatRep(email);

    if (data?.Detail?.length > 0) {
      dispatch({
        type: CHAT_HISTORY,
        payload: data,
      });
      onSuccess();
    } else {
      dispatch({
        type: CHAT_HISTORY,
        payload: [],
      });
    }
  } catch (error) {
    console.error("Error logging out:", error.message);
  }
};
export const chatDetailAction = (id, onSuccess) => async (dispatch) => {
  try {
    const { data } = await chatting.chatDetailRep(id);
    console.log("chat data detail---", data);
    console.log(data, "data");
    if (data?.Detail.History?.history.length > 0) {
      dispatch({
        // type: "CHATBOTReply",
        type: CHAT_DETAIL,
        payload: {
          chatting: data?.Detail.History?.history,
          chatId: data.Detail.Id,
        },
      });

      dispatch(Loader(false));
    } else {
      alert("Sorry Response Failed!");
      dispatch(Loader(false));
    }
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

const Loader = (data) => async (dispatch) => {
  dispatch({
    type: "LOADER",
    payload: data,
  });
};

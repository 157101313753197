import {
  AUTH_NOTIFICATION,
  AUTH_SET_LOADING,
  BLOCK_LOADER,
  GET_ADMIN_USER,
  GET_LOADER,
  GET_USER,
  LOADER,
  LOGIN_LOADER,
} from "../types";

const initialState = {
  loading: false,
  blockLoading: false,
  getLoader: false,
  users: [],
  adminUsers: [],
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case GET_LOADER:
      return {
        ...state,
        getLoader: payload,
      };
    case BLOCK_LOADER:
      return {
        ...state,
        blockLoading: payload,
      };
    case GET_USER:
      return {
        ...state,
        users: payload,
      };
    case GET_ADMIN_USER:
      return {
        ...state,
        adminUsers: payload,
      };
    default:
      return {
        ...state,
      };
  }
}

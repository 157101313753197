import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spinner,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { HiOutlineSearch } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";
import { RichTextViewer } from "./RichTextViewer";
import { getNewsAction } from "store/actions/newsAction";
import { publishNews } from "store/actions/newsAction";
import { deleteNews } from "store/actions/newsAction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { updatehNewsAction } from "store/actions/newsAction";

const SearchNews = () => {
  const [search, setSearch] = useState("");
  const [Content, setContent] = useState("");
  const dispatch = useDispatch();
  const { loading, newsData, deleteloading, publishloading, updateloading } =
    useSelector((state) => state.news);
    console.log(publishloading,'its publishloading')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getNewsAction(search));
  };
  const handlePublish = (e, Id) => {
    e.preventDefault();
    dispatch(publishNews(Id));
  };
  const handleDeleteNews = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const toggleDeleteModal = (item) => {
    console.log("this is cancel btn of modal");
    setItemToDelete(item);
    setDeleteModalOpen(!deleteModalOpen);
  };
  const handleDelete = (e, Id) => {
    e.preventDefault();
    dispatch(deleteNews(Id));
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    if (newsData?.response) {
      setContent(newsData?.response);
    }
  }, [newsData?.response]);

  const handleUpdate = (e, id) => {
    e.preventDefault();
    let obj = {
      NewsId: id,
      Content: Content,
    };
    dispatch(updatehNewsAction(obj));
  };
  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ align: [] }],
      [{ color: [] }],
      ["code-block"],
      ["clean"],
    ],
  };
  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "align",
    "color",
    "code-block",
  ];
  return (
    <div className="container">
      <Row>
        <Col>
          <>
            <div className="main_search  ">
              <div className="d-flex outer-input-div outer-input-div align-items-center border border-1 rounded px-1  col-md-6 mt-3  ">
                <HiOutlineSearch size={20} className="ml-2" />
                <input
                  type="text"
                  placeholder="Email,name,phone..."
                  // value={searchTerm}
                  className="form-control input-field mx-2 rounded-5 border-0 p-10"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <Button
                disabled={loading}
                className="bg-success text-white hover:cursor-pointer"
                onClick={handleSearch}
              >
                {loading ? <Spinner size="sm" /> : "Search"}
              </Button>
            </div>
          </>
        </Col>
      </Row>
      {newsData != null && (
        <>
          <Row>
            <Col>
              <div className="card_container">
                <div className="card_texts">
                  <ReactQuill
                     modules={quillModules}
                     formats={quillFormats}
                    theme="snow"
                    value={Content}
                    onChange={setContent}
                  />
                </div>
                <div className="card_btns">
                  {!newsData?.Published && (
                    <Button
                    disabled={publishloading}
                      className="bg-success cursor-pointer text-white hover:cursor-pointer"
                      onClick={(e) => {
                        handlePublish(e, newsData?.Id);
                      }}
                    >
                      {publishloading ? <Spinner size="md" /> : "Publish"}
                    </Button>
                  )}
                  <Button
                    disabled={updateloading}
                    className="mx-2 bg-success cursor-pointer text-white hover:cursor-pointer"
                    onClick={(e) => {
                      handleUpdate(e, newsData?.Id);
                    }}
                  >
                    {updateloading ? <Spinner size="md" /> : "Update"}
                  </Button>

                  <Button
                    className="bg-danger text-white cursor-pointer"
                    onClick={handleDeleteNews}
                  >
                    {deleteloading ? <Spinner size="md" /> : "Delete"}
                  </Button>
                </div>
              </div>
            </Col>
            {/* <Button className="bg-danger text-white cursor-pointer" onClick={(e) => handleDeleteNews(e, newsData?.Id)}>
                                {deleteloading ? <Spinner size='md' /> : 'Delete'}
                            </Button> */}

            <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
              <ModalHeader toggle={toggleDeleteModal}>
                Delete Confirmation
              </ModalHeader>
              <ModalBody>Are you sure you want to delete this item?</ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={(e) => {
                    handleDelete(e, newsData?.Id);
                  }}
                >
                  Delete
                </Button>{" "}
                <Button color="secondary" onClick={toggleDeleteModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
        </>
      )}
    </div>
  );
};

export default SearchNews;

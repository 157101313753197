import {
  GET_ADMIN_TICKETS,
  GET_ADMIN_USER,
  GET_LOADER,
  GET_TICKETS,
  GET_TICKETS_CHAT,
  LOGIN_LOADER,
} from "../types";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
var ticket = RepositoryFactory.get("ticket");

export const setAuthLoading = (val) => async (dispatch) => {
  dispatch({ type: LOGIN_LOADER, payload: val });
};

export const setGetLoading = (val) => async (dispatch) => {
  dispatch({ type: GET_LOADER, payload: val });
};

export const assignTicketAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await ticket.assignTicketRep(payload);

    if (data?.status_code == 200) {
      alert(data?.detail);
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      alert(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    alert(e.message);
    await dispatch(setAuthLoading(false));
  }
};

export const getTicketsAction = () => async (dispatch) => {
  try {
    await dispatch(setGetLoading(true));
    const { data } = await ticket.getTicketsRep();

    if (data?.status_code == 200) {
      dispatch({ type: GET_TICKETS, payload: data?.detail });
      await dispatch(setGetLoading(false));
    } else {
      alert(data?.detail);
      dispatch({ type: GET_ADMIN_USER, payload: [] });
      await dispatch(setGetLoading(false));
    }
  } catch (error) {
    console.error("Error ", error?.message);
    await dispatch(setGetLoading(false));
  }
};

export const getadminTicketAction = (id) => async (dispatch) => {
  try {
    await dispatch(setGetLoading(true));
    const { data } = await ticket.getAdminTicketRep(id);
    
    if (data?.status_code == 200) {
      dispatch({ type: GET_ADMIN_TICKETS, payload: data?.detail });
      await dispatch(setGetLoading(false));
    } else {
      alert(data?.detail);
      dispatch({ type: GET_ADMIN_TICKETS, payload: [] });
      await dispatch(setGetLoading(false));
    }
  } catch (error) {
    console.error("Error ", error?.message);
    await dispatch(setGetLoading(false));
  }
};

export const ticketChatAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await ticket.ticketChatRep(payload);

    if (data?.status_code == 200) {
      // alert(data?.detail);
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      // alert(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    // alert(e.message);
    await dispatch(setAuthLoading(false));
  }
};
export const getTicketChatAction = (id) => async (dispatch) => {
  try {
    await dispatch(setGetLoading(true));
    const { data } = await ticket.getTicketChatRep(id);
    if (data?.status_code == 200) {
      dispatch({ type: GET_TICKETS_CHAT, payload: data?.detail });
      await dispatch(setGetLoading(false));
    } else {
      // alert(data?.detail);
      dispatch({ type: GET_TICKETS_CHAT, payload: [] });
      await dispatch(setGetLoading(false));
    }
  } catch (error) {
    console.error("Error ", error?.message);
    await dispatch(setGetLoading(false));
  }
};

import Repository from './Repository';
const HISTORY_CHAT = "Chat/Get-Chat-History/";
const CHAT_DETAIL = "/Chat/Get-Chat-History-By-Id/";
export default { 
    
    historyChatRep(email) {
        return Repository.get(`${HISTORY_CHAT}${email}`);
    },

    chatDetailRep(id) {
        return Repository.get(`${CHAT_DETAIL}${id}`);
    },
}
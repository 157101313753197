import { SUBSCRIPTION, SUBSCRIPTION_LOADER } from "../types";

const initialState = {
  subscriptionData: [],
  loading: false,
};

export default function subscriptionReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: payload,
      };
    case SUBSCRIPTION_LOADER:
      return {
        ...state,
        loading: payload,
      };
    default:
      return {
        ...state,
      };
  }
}

import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Spinner,
  Modal,
  ModalBody,
  Col,
} from "reactstrap";

// Import your OnlyHeader component
import OnlyHeader from "components/Headers/OnlyHeader.js";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { chatHistoryAction } from "store/actions/chattingAction";
import { PreviousChat } from "components/PreviousChat";
import { chatDetailAction } from "store/actions/chattingAction";
import { ChatBotMessages } from "components/ChatBotMessages";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

const ChatHistory = () => {
  const dispatch = useDispatch();
  const [chatId, setChatId] = useState([]);
  const [chats, setChats] = useState([]);
  const [visibleItemsMap, setVisibleItemsMap] = useState([]);
  console.log("visibleItemsMap", visibleItemsMap);
  const { id } = useParams();
  const { state } = useLocation();
  console.log("state", state);
  const [isLarge, setIsLarge] = useState(false);
  console.log("isLarge", isLarge);

  const updatePredicate = () => {
    setIsLarge(window.innerWidth < 1200);
  };
  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, []);

  const { chat, loading, chattingId, historyChat, delete_loader, sitesData } =
    useSelector((state) => state.chatting);

  useEffect(() => {
    dispatch(chatHistoryAction(state?.Email, () => {}));
  }, [state]);

  useEffect(() => {
    if (historyChat?.Detail?.length > 0) {
      let index = historyChat?.Detail?.length - 1;
      setChatId(historyChat?.Detail[index].Id);
    }
  }, [historyChat?.Detail]);
  useEffect(() => {
    setChats(chat);
    setVisibleItemsMap(Array(chat?.length).fill(0));
  }, [chat]);
  const [toggle, setToggle] = useState(true);

  const handleShowMoreForIndex = (index) => {
    setVisibleItemsMap((prev) => {
      const newVisibleItemsMap = [...prev];
      newVisibleItemsMap[index] = (newVisibleItemsMap[index] || 0) + 3;
      return newVisibleItemsMap;
    });
  };
  useEffect(() => {
    if (chatId != -1) {
      dispatch(chatDetailAction(chatId));
    } else {
      setChats([]);
    }
  }, [chatId]);

  console.log("is", toggle);
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <Col className="chat_history">
            <Card className="shadow chat_history__card">
              <CardHeader className="border-0 ">
                <Row className="d-flex justify-content-between align-items-center">
                  <Col xs="6">
                    <h3 className="mb-0">Chat History</h3>
                  </Col>
                  <Col xs="6" className="d-flex justify-content-end">
                    {isLarge && (
                      <div>
                        {!toggle && (
                          <Button
                            className="px-3 py-2 cursor-pointer text-white    rounded  text-black bg-gradient-info"
                            onClick={() => {
                              setToggle(!toggle);
                            }}
                          >
                            Back
                          </Button>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              {historyChat?.Detail?.length > 0 ? (
                <>
                  <Row className="px-3">
                    {isLarge ? (
                      <>
                        {toggle ? (
                          <Col
                            // xs={6}
                            // md={6}
                            // lg={5}
                            xl={4}
                            className="chat_history__sidebar "
                          >
                            <PreviousChat
                              chatId={chatId}
                              setChatId={setChatId}
                              state={state}
                              toggle={toggle}
                              setToggle={setToggle}
                            />
                          </Col>
                        ) : (
                          <Col xl={8} className=" ">
                            <h4 className="px-3 bg-gradient-info  py-3 rounded text-white text-center">
                              Chat Detail
                            </h4>
                            {chats?.length == 0 ? (
                              <>
                                <p className="d-flex justify-content-center align-items-center  ">
                                  NO Chat
                                </p>
                              </>
                            ) : (
                              <div className=" mt-3  pb-5 chat_history__message">
                                <ChatBotMessages
                                  chats={chats}
                                  visibleItemsMap={visibleItemsMap}
                                  handleShowMoreForIndex={
                                    handleShowMoreForIndex
                                  }
                                />
                              </div>
                            )}
                          </Col>
                        )}
                      </>
                    ) : (
                      <>
                        <Col
                          // xs={6}
                          // md={6}
                          // lg={5}
                          xl={4}
                          className="chat_history__sidebar"
                        >
                          <PreviousChat
                            chatId={chatId}
                            setChatId={setChatId}
                            state={state}
                            toggle={toggle}
                            setToggle={setToggle}
                          />
                        </Col>
                        <Col xl={8} className=" ">
                          <h4 className="px-3 bg-gradient-info  py-3 rounded text-white text-center">
                            Chat Detail
                          </h4>
                          {chats?.length == 0 ? (
                            <>
                              <p className="d-flex justify-content-center align-items-center  ">
                                NO Chat
                              </p>
                            </>
                          ) : (
                            <div className=" mt-3  pb-5 chat_history__message">
                              <ChatBotMessages
                                chats={chats}
                                visibleItemsMap={visibleItemsMap}
                                handleShowMoreForIndex={handleShowMoreForIndex}
                              />
                            </div>
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col className="py-4">
                      <div className="text-center">No Chat History Found</div>
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChatHistory;

import React, { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  Nav,
  Button,
  Spinner,
  Container,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionAction } from "store/actions/subscriptionAction";
import axios from "axios";
import OnlyHeader from "components/Headers/OnlyHeader.js";
import SearchNews from "components/SearchNews";
import { HiOutlineSearch } from "react-icons/hi";
import { getNewsAction } from "store/actions/newsAction";
import ViewPublishNews from "components/ViewPublishNews";
const Blogs = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState(1);
  const [newsResponse, setNewsResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(getSubscriptionAction());
  }, []);

  const toggle = (tab) => {
    setCurrentActiveTab(tab);
  };


  // setIsLoading(true);
  // axios
  //   .get(`http://192.168.18.106:8000/News/Get-News?Query=${search}`)
  //   .then((res) => {
  //     console.log(res);
  //     if (res.data.status_code === 200) {
  //       setNewsResponse(res.data.detail.response);
  //     }
  //   })
  //   .finally(() => {
  //     setIsLoading(false);
  //   });



  // const renderText = () => {
  //   // Splitting the text into paragraphs
  //   const paragraphs = text?.split('\n\n');

  //   return paragraphs?.map((paragraph, index) => (
  //     <p key={index}>{renderParagraph(paragraph)}</p>
  //   ));
  // };

  // return <div>{renderText()}</div>;





  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">News</h3>
                <Nav tabs className="d-flex">
                  <div className="d-flex tabBackground rounded p-1">

                    <Button
                      className={` ${currentActiveTab === 1 ? "bg-gradient-info text-white" : " active-tab "
                        }`}
                      onClick={() => toggle(1)}
                    >
                      Search
                    </Button>


                    <Button className={` ${currentActiveTab === 2 ? "bg-gradient-info text-white" : " active-tab "
                        }`} onClick={() => toggle(2)}>
                      View
                    </Button>

                  </div>
                </Nav>
                {currentActiveTab === 1 && (
                  <SearchNews />
                )}
                {currentActiveTab === 2 &&
                  <div>
                    <ViewPublishNews />
                  </div>}

              </CardHeader>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default Blogs;

import React from 'react';
import ViewPublishNews from './ViewPublishNews';
function MarkDownRenderer({ text }) {
    return (
        <div className="  overflow-auto    " >
            <div dangerouslySetInnerHTML={{
                __html: text
            }} />
        </div>
    );
}
export default MarkDownRenderer;
import Repository from "./Repository";

const SEARCH_NEWS = "/News/Get-News?Query=";
const PUBLISH_NEWS = "/News/Publish-News?NewsId=";
const GET_ALL_PUBLISH_NEWS = "/News/Get-All-Published-News";
const DELETE_NEWS = "/News/Delete-News?NewsId=";
const UPDATE_NEWS = "/News/Update-News";
export default {
  getSearchNewsRep(id) {
    console.log(id, "its a repositary id..");
    return Repository.get(`${SEARCH_NEWS}${id}`);
  },
  postPublishNews(id) {
    return Repository.post(`${PUBLISH_NEWS}${id}`);
  },
  updateNewsRep(payload) {
    return Repository.put(`${UPDATE_NEWS}`, payload);
  },
  getAllPublishNews() {
    return Repository.get(`${GET_ALL_PUBLISH_NEWS}`);
  },
  deleteAllNews(id) {
    return Repository.delete(`${DELETE_NEWS}${id}`);
  },
};

import React from "react";
import { Button, Col, Row } from "reactstrap";
import userchat from "../assets/img/userchat.jpeg";

import botchat from "../assets/img/botchat.jpeg";

export const ChatBotMessages = ({
  chats,
  visibleItemsMap,
  data,
  chatContainerRef,
  handleShowMoreForIndex,
}) => {
  function parseTextToObjects(text) {
    const pattern =
      /Title: (.*?)##data##Description: (.*?)##data##Url: (.*?)##data##Image: (.*?)#@data@#/g;
    let matches;
    const objects = [];

    while ((matches = pattern.exec(text)) !== null) {
      objects.push({
        title: matches[1],
        description: matches[2],
        url: matches[3],
        image: matches[4],
      });
    }
    return objects;
  }

  return (
    <>
      {chats?.length > 0 &&
        chats?.map((item, index) => {
          return (
            <>
              <Row className="py-3 pl-1  border-b-2 ">
                <Col>
                  <Row>
                    <Col
                      md="1"
                      xs="2"
                      className="justify-content-center d-flex"
                    >
                      <div
                        className="avatar bg-transparent avatar-sm rounded-circle"
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      >
                        <img src={userchat} className="img-fluid" />
                      </div>
                    </Col>
                    <Col xs="10" md="11" className="px-0">
                      <div className="px-2">
                        <p>{item?.User}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="py-3 pl-1 border-b-2 ">
                <Col>
                  <Row>
                    <Col
                      md="1"
                      xs="2"
                      className="justify-content-center d-flex"
                    >
                      <div
                        className="avatar bg-transparent avatar-sm rounded-circle   "
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      >
                        <img src={botchat} />
                      </div>
                    </Col>
                    <Col xs="10" md="11" className="pl-0 pr-4">
                      <div className="px-2">
                        <p className="chat-message-user">
                          {typeof item?.AI === "string" &&
                          item?.AI.startsWith("http") ? (
                            <img src={item.AI} alt="" className="img-fluid" />
                          ) : typeof item?.AI === "string" &&
                            !item?.AI.startsWith("http") ? (
                            <>
                              <div
                                style={{
                                  whiteSpace: "pre-wrap",
                                  wordBreak: "break-word",
                                }}
                              >
                                {item?.AI?.split("##end##")[0]}
                              </div>
                              <Row className="py-3">
                                {parseTextToObjects(item?.AI)?.map(
                                  (obj, index) => (
                                    <>
                                      <Col
                                        sm="6"
                                        lg="4"
                                        className="card_plugin my-1"
                                      >
                                        <a href={obj?.url} target="_blank">
                                          <div className="shadow bg-[#fff] p-2">
                                            <div className="box">
                                              <img src={obj?.image} />
                                              <div className="is-clipped">
                                                <div
                                                  style={{
                                                    wordBreak: "break-word",
                                                  }}
                                                  className=" preview__tiltle"
                                                  dangerouslySetInnerHTML={{
                                                    __html: obj?.title,
                                                  }}
                                                ></div>
                                                <div
                                                  style={{
                                                    wordBreak: "break-word",
                                                  }}
                                                  className="mt-2 preview__description"
                                                  dangerouslySetInnerHTML={{
                                                    __html: obj?.description,
                                                  }}
                                                ></div>
                                                <div
                                                  style={{
                                                    wordBreak: "break-word",
                                                  }}
                                                  className="mt-2 is-size-7 text-primary"
                                                  dangerouslySetInnerHTML={{
                                                    __html: obj?.url,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </a>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            </>
                          ) : item ? (
                            <>
                              {item?.AI?.web
                                ?.slice(0, (visibleItemsMap[index] || 0) + 3)
                                ?.map((item, idx) => (
                                  <>
                                    <div className="mb-3 border-b-2  pb-1">
                                      <div>
                                        <div className="flex items-center">
                                          <span className="font-bold mr-3">
                                            Website:
                                          </span>
                                          <span>
                                            {item?.profile.img && (
                                              <div className=" ">
                                                <img
                                                  className=" img-fluid "
                                                  style={{
                                                    height: 10,
                                                  }}
                                                  src={item?.profile.img}
                                                  alt="Sunset in the mountains"
                                                />
                                              </div>
                                            )}
                                          </span>
                                        </div>

                                        <span className="font-bold mr-3">
                                          Title:
                                        </span>
                                        <span>{item?.title} </span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="font-bold  mr-3">
                                          Url:
                                        </span>

                                        <span className=" cursor-pointer">
                                          <a
                                            style={{
                                              wordBreak: "break-word",
                                            }}
                                            className="url-text"
                                            href={item?.url}
                                            target="_blank" // Add this line to open the link in a new tab
                                            rel="noopener noreferrer" // Add these lines for security reasons
                                          >
                                            {item?.url}
                                          </a>
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        <span className="font-bold  mr-3">
                                          {" "}
                                          Description:
                                        </span>
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: item.description,
                                          }}
                                        ></span>
                                      </div>
                                    </div>
                                  </>
                                ))}

                              {visibleItemsMap[index] <
                                (item?.AI?.web?.length || 0) && (
                                <>
                                  <div className="text-center">
                                    <Button
                                      className="text-white cursor-pointer px-3 py-2 rounded-md bg-gradient-info cursor-pointer"
                                      onClick={() =>
                                        handleShowMoreForIndex(index)
                                      }
                                    >
                                      Show More
                                    </Button>
                                  </div>
                                </>
                              )}
                              <>
                                {item?.AI?.type == "summarizer" &&
                                  item?.AI?.results
                                    ?.slice(
                                      0,
                                      (visibleItemsMap[index] || 0) + 3
                                    )
                                    ?.map((item, idx) => (
                                      <>
                                        <div className="mb-3 ">
                                          <div>
                                            <span className="font-bold mr-3">
                                              Title:
                                            </span>
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: item?.answer?.text,
                                              }}
                                            ></span>
                                          </div>

                                          <div
                                            style={{
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <span className="font-bold  mr-3">
                                              {" "}
                                              Summary:
                                            </span>
                                            <span>{item?.summary}</span>
                                          </div>
                                          <div className="">
                                            <span className="font-bold mr-3">
                                              References:
                                            </span>

                                            {item?.references?.map((obj) => (
                                              <>
                                                <div className=" border-b-2  py-2">
                                                  <div className="d-flex align-items-center">
                                                    <span className="font-bold mr-3">
                                                      Website:
                                                    </span>
                                                    <span>
                                                      {obj?.img && (
                                                        <div className=" ">
                                                          <img
                                                            className="h-10 img-fluid "
                                                            src={obj?.img}
                                                            alt="Sunset in the mountains"
                                                          />
                                                        </div>
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div className="flex items-center">
                                                    <span className="font-bold  mr-3">
                                                      Url:
                                                    </span>

                                                    <span className=" cursor-pointer">
                                                      <a
                                                        style={{
                                                          wordBreak:
                                                            "break-word",
                                                        }}
                                                        className="url-text"
                                                        href={obj?.url}
                                                        target="_blank" // Add this line to open the link in a new tab
                                                        rel="noopener noreferrer" // Add these lines for security reasons
                                                      >
                                                        {obj?.url}
                                                      </a>
                                                    </span>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </div>
                                        </div>
                                      </>
                                    ))}

                                {visibleItemsMap[index] <
                                  (item?.AI?.results?.length > 3 || 0) && (
                                  <>
                                    <div className="text-center">
                                      <button
                                        className=" px-3 py-2 rounded-[12px] cursor-pointer"
                                        onClick={() =>
                                          handleShowMoreForIndex(index)
                                        }
                                      >
                                        Show More
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            </>
                          ) : null}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          );
        })}
    </>
  );
};

// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";

import authReducer from "./authReducer";
import waitListReducer from "./waitListReducer";
import subscriptionReducer from "./subscriptionReducer";
import ticketReducer from "./ticketReducer";
import chattingsReducer from "./chattingsReducer";
import newsReducer from "./newsReducer";
export const appReducer = combineReducers({
  authUser: authUserReducer,
  userData: authReducer,
  waitList: waitListReducer,
  subscription: subscriptionReducer,
  chatting: chattingsReducer,
  ticket: ticketReducer,
  news:newsReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;

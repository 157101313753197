import { LOADER, LOGIN, LOGOUT, UPDATE_PROFILE } from "../types";

const initialState = {
  user: null,
  token: null,
  uid: null,
};

/*
    Any action related to Profile will go here.
*/

export default function authUserReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOGIN:
      console.log("payload", payload);
      return {
        ...state,
        user: payload,
        token: payload.detail.AccessToken,
        uid: payload.detail.UserId,
      };
    case LOGOUT:
      localStorage.removeItem("auth");
      return {
        ...state,
        user: null,
        token: null,
        uid: null,
      };
    default:
      return { ...state };
  }
}

import React from "react";
import { useSelector } from "react-redux";

export const PreviousChat = ({
  chatId,
  setChatId,
  state,
  setToggle,
  toggle,
}) => {
  const { chat, loading, chattingId, historyChat, delete_loader, sitesData } =
    useSelector((state) => state.chatting);
  return (
    <>
      <div className="bg-white  py-1 sidebar_message">
        {historyChat.Detail?.slice()
          ?.sort((a, b) => b.Id - a.Id)
          ?.map((obj) => (
            <>
              <div
                onClick={() => {
                  setChatId(obj?.Id);
                  setToggle(!toggle);
                }}
                className={`${
                  obj?.Id === chatId
                    ? "text-white  chatting-box bg-gradient-info"
                    : ``
                } my-1  py-1 rounded px-2 mx-1 cursor-pointer sidebar_message__box `}
              >
                <div className="d-flex  align-items-center">
                  <div
                    className={`${
                      obj?.Id === chatId
                        ? "text-black bg-light  chatting-box "
                        : `bg-box text-white`
                    } rounded-circle d-flex justify-content-center align-items-center`}
                    style={{ width: 34, height: 34 }}
                  >
                    {state?.UserName.slice(0, 1)}
                  </div>
                  <div className="fs--14  fw--400 mx-2">
                    <div className="">{state?.UserName}</div>
                    <div>{obj?.FirstMessage?.slice(0, 30)}</div>
                  </div>
                </div>
                <div className="fs--12   text-right">{obj?.DateTime}</div>
              </div>
              <div className="bg-dark ">
                <hr className="my-1 sidebar_message__line" />
              </div>
            </>
          ))}
      </div>
    </>
  );
};

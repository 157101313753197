import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Spinner,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSubscriptionAction } from "store/actions/subscriptionAction";
const Subscription = () => {
  const dispatch = useDispatch();

  const { subscriptionData, loading } = useSelector(
    (state) => state.subscription
  );

  useEffect(() => {
    dispatch(getSubscriptionAction());
  }, []);
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Subscription User</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Plan</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <>
                      <tr>
                        <td colspan="6">
                          <div className="mt-2 d-flex align-items-center  py-4 justify-content-center">
                            <div>
                              <Spinner />
                            </div>
                          </div>{" "}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {subscriptionData?.map((obj) => (
                        <>
                          <tr>
                            <td>{obj?.Username}</td>
                            <td>{obj?.Email}</td>
                            <td>{obj?.Plan}</td>
                            <td>{obj?.StartDate}</td>
                            <td>{obj?.EndDate}</td>
                            <td>{obj?.Status}</td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}
                  {subscriptionData?.length == 0 && (
                    <>
                      <tr>
                        <td colspan="6">
                          {" "}
                          <div className="mt-2  d-flex align-items-center py-4 justify-content-center w-full">
                            <div>No Record Found</div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Subscription;

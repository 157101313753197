import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Spinner,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import { getadminTicketAction } from "store/actions/ticketAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AdminTicket = () => {
  const dispatch = useDispatch();

  const [assignModal, setAssignModal] = useState(false);
  const [id, setId] = useState();
  const [adminId, setAdminId] = useState(null);

  console.log("adminid", adminId);

  const assignToggle = () => {
    setAssignModal(!assignModal);
  };
  const { uid } = useSelector((state) => state.authUser);
  const { getLoader } = useSelector((state) => state.userData);

  const { adminTickets } = useSelector((state) => state.ticket);

  useEffect(() => {
    if (uid) {
      dispatch(getadminTicketAction(uid));
    }
  }, [uid]);
  const history = useHistory();

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-0">Admin Ticket </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Ticket Id</th>
                    <th scope="col">Ticket Tilte</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {getLoader ? (
                    <>
                      <tr>
                        <td colspan="4">
                          <div className="mt-2 d-flex align-items-center  py-4 justify-content-center">
                            <div>
                              <Spinner />
                            </div>
                          </div>{" "}
                        </td>

                        <td></td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {adminTickets?.map((obj) => (
                        <>
                          <tr>
                            <td>{obj?.TicketId}</td>
                            <td>{obj?.Title}</td>
                            <td>{obj?.Email}</td>
                            <td>{obj?.Status}</td>
                            <td>
                              {obj?.Status == "In Progress" && (
                                <>
                                  <Button
                                    className="bg-gradient-info cursor-pointer text-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      history.push({
                                        pathname: `/admin/support-ticket/ticket_chat/${obj?.TicketId}`,
                                        state: obj, // your data array of objects
                                      });
                                    }}
                                  >
                                    Chat
                                  </Button>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}

                  {adminTickets?.length == 0 && (
                    <>
                      <tr>
                        <td colspan="4">
                          {" "}
                          <div className="mt-2  d-flex align-items-center py-4 justify-content-center w-full">
                            <div>No Record Found</div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AdminTicket;

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Spinner,
  Modal,
  Col,
  ModalBody,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getWaitListAction } from "store/actions/waitListAction";
import { changeWaitListAction } from "store/actions/waitListAction";

const WaitList = () => {
  const dispatch = useDispatch();
  const { waitListData, loading, statusLoading } = useSelector(
    (state) => state.waitList
  );

  useEffect(() => {
    dispatch(getWaitListAction());
  }, []);
  const [activate, setActivate] = useState(false);
  const [deactivate, setDeActivate] = useState(false);
  const [id, setId] = useState("");
  const activateToggle = () => {
    setActivate(!activate);
  };
  const deactivateToggle = () => {
    setDeActivate(!deactivate);
  };
  const handleActivate = () => {
    dispatch(
      changeWaitListAction(
        {
          Id: id,
          status: "activate",
        },
        () => {
          dispatch(getWaitListAction());
          activateToggle();
          setId("");
        }
      )
    );
  };
  const handleDeactivate = () => {
    dispatch(
      changeWaitListAction(
        {
          Id: id,
          status: "deactivate",
        },
        () => {
          dispatch(getWaitListAction());
          setId("");
          deactivateToggle();
        }
      )
    );
  };
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Wait List User</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <>
                      <tr>
                        <td colspan="3">
                          <div className="mt-2 d-flex align-items-center  py-4 justify-content-center">
                            <div>
                              <Spinner />
                            </div>
                          </div>{" "}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {waitListData?.map((obj) => (
                        <>
                          <tr>
                            <td>{`${obj?.FirstName}${" "} ${
                              obj?.LastName
                            }`}</td>
                            <td>{obj?.Email}</td>
                            <td>
                              <Button
                                className="bg-success cursor-pointer text-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  activateToggle();
                                  setId(obj?.Id);
                                }}
                              >
                                Activate
                              </Button>
                              <Button
                                className="bg-danger text-white cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  deactivateToggle();
                                  setId(obj?.Id);
                                }}
                              >
                                Deactivate
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}

                  {waitListData?.length == 0 && (
                    <>
                      <tr>
                        <td colspan="3">
                          {" "}
                          <div className="mt-2  d-flex align-items-center py-4 justify-content-center w-full">
                            <div>No Record Found</div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal
        isOpen={activate}
        toggle={activateToggle}
        size="md"
        centered={true}
        className="modal"
      >
        <div className="bg-gradient-info modal__header d-flex justify-content-between align-items-center px-4 py-4">
          <div className="fs--18 fw--600">ACTIVATE USER</div>
          <div className="cursor-pointer" onClick={activateToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                fill="#ffff"
                fill-opacity="0.4"
              />
              <path
                d="M9.10205 14.7568L14.8977 9.24228"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <ModalBody className="px-4 py-5">
          <Row>
            <Col>
              <div className="text-center fs--24 fw--600 text-dark  ">
                Are you sure you want to activate this user?
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <div className="text-right">
                <Button
                  className="bg-gradient-info program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                  onClick={handleActivate}
                  disabled={statusLoading}
                >
                  {statusLoading ? <Spinner size="sm" /> : `${"Yes"}`}
                </Button>
                <Button
                  className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                  onClick={(e) => {
                    e.preventDefault();
                    activateToggle();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deactivate}
        toggle={deactivateToggle}
        size="md"
        centered={true}
        className="modal"
      >
        <div className="bg-gradient-info modal__header d-flex justify-content-between align-items-center px-4 py-4">
          <div className="fs--18 fw--600">DEACTIVATE USER</div>
          <div className="cursor-pointer" onClick={deactivateToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                fill="#ffff"
                fill-opacity="0.4"
              />
              <path
                d="M9.10205 14.7568L14.8977 9.24228"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <ModalBody className="px-4 py-5">
          <Row>
            <Col>
              <div className="text-center fs--24 fw--600 text-dark  ">
                Are you sure you want to deactivate this user?
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <div className="text-right">
                <Button
                  className="bg-gradient-info program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                  onClick={handleDeactivate}
                  disabled={statusLoading}
                >
                  {statusLoading ? <Spinner size="sm" /> : `${"Yes"}`}
                </Button>
                <Button
                  className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                  onClick={(e) => {
                    e.preventDefault();
                    deactivateToggle();
                    setId("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};
export default WaitList;

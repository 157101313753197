import {
  GET_SEARCH_NEWS,
  GET_ALL_PUBLISH_NEWS,
  NEWS_LOADER,
  PUBLISH_LOADER,
  UPDATE_LOADER,
} from "../types";

const initialState = {
  newsData: null,
  allPublishdata: [],
  loading: false,
  publishloading: false,
  updateloading: false,
  deleteloading: false,
};
export default function newsReducer(state = initialState, { type, payload }) {
  switch (type) {
    // GET API REDUCER
    case GET_SEARCH_NEWS:
      console.log("this is payload", payload);
      return {
        ...state,
        newsData: payload,
      };
    case NEWS_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case UPDATE_LOADER:
      return {
        ...state,
        updateloading: payload,
      };
    // GET API REDUCER
    case GET_ALL_PUBLISH_NEWS:
      return {
        ...state,
        allPublishdata: payload,
      };
      case PUBLISH_LOADER:
        return{
          ...state,
          publishloading:payload,
        };
    default:
      return {
        ...state,
      };
  }
}

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Spinner,
  ModalBody,
  Col,
  Modal,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import ChatHistory from "./ChatHistory";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { getUserAction } from "store/actions/authAction";
import { blockUserAction } from "store/actions/authAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "components/Pagination";

const ActivateUsers = () => {
  const dispatch = useDispatch();
  const [block, setBlock] = useState(false);
  const [page, setPage] = useState(1);
  const [id, setId] = useState();
  const blockToggle = () => {
    setBlock(!block);
  };
  const { users, loading, blockLoading } = useSelector(
    (state) => state.userData
  );

  useEffect(() => {
    dispatch(getUserAction(page));
  }, [page]);
  const handleBlocked = () => {
    dispatch(
      blockUserAction(id, () => {
        blockToggle();
        setId("");
        dispatch(getUserAction(page));
      })
    );
  };

  const history = useHistory();

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0"> User</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <>
                      <tr>
                        <td colspan="3">
                          <div className="mt-2 d-flex align-items-center  py-4 justify-content-center">
                            <div>
                              <Spinner />
                            </div>
                          </div>{" "}
                        </td>

                        <td></td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {users?.users?.map((obj) => (
                        <>
                          <tr>
                            <td>{obj?.UserName}</td>
                            <td>{obj?.Email}</td>
                            <td>
                              <Button
                                className="bg-danger cursor-pointer text-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  blockToggle();
                                  setId(obj?.Id);
                                }}
                              >
                                Block
                              </Button>

                              {/* Add button for Chat History */}

                              <Button
                                className="bg-info cursor-pointer text-white"
                                onClick={(e) => {
                                  history.push({
                                    pathname: `/super_admin/activate-user/chat_history`,
                                    state: obj, // your data array of objects
                                  });
                                  e.preventDefault();
                                  setId(obj?.email);
                                }}
                              >
                                Chat History
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}

                  {users?.users?.length == 0 && (
                    <>
                      <tr>
                        <td colspan="3">
                          {" "}
                          <div className="mt-2  d-flex align-items-center py-4 justify-content-center w-full">
                            <div>No Record Found</div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {users?.users?.length > 0 && (
          <div className="my-4">
            <Pagination
              page={page}
              setPage={(newPage) => setPage(newPage)}
              totalPages={users?.TotalPages}
            />
          </div>
        )}
      </Container>

      <Modal
        isOpen={block}
        toggle={blockToggle}
        size="md"
        centered={true}
        className="modal"
      >
        <div className="bg-gradient-info modal__header d-flex justify-content-between align-items-center px-4 py-4">
          <div className="fs--18 fw--600">BLOCK USER</div>
          <div className="cursor-pointer" onClick={blockToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                fill="#ffff"
                fill-opacity="0.4"
              />
              <path
                d="M9.10205 14.7568L14.8977 9.24228"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <ModalBody className="px-4 py-5">
          <Row>
            <Col>
              <div className="text-center fs--24 fw--600 text-dark  ">
                Are you sure you want to block this user?
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <div className="text-right">
                <Button
                  className="bg-gradient-info program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                  onClick={handleBlocked}
                  disabled={blockLoading}
                >
                  {blockLoading ? <Spinner size="sm" /> : `${"Yes"}`}
                </Button>
                <Button
                  className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                  onClick={(e) => {
                    e.preventDefault();
                    blockToggle();
                    setId("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ActivateUsers;

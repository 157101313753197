import { CHAT_DETAIL, CHAT_HISTORY } from "../types";

const initialState = {
  chat: [],
  historyChat: [],
  detailChat: [],
  loading: false,
  chattingId: -1,
};

export default function chattingsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CHAT_DETAIL:
      return {
        ...state,
        chat: payload.chatting,
        chattingId: payload.chatId,
      };

    case CHAT_HISTORY:
      console.log("coming....");
      return {
        ...state,
        historyChat: payload,
      };
    default:
      return {
        ...state,
      };
  }
}

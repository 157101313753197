import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Spinner,
  ModalBody,
  Col,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText,
  Label,
  CardBody,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import Chatting from "components/Chatting";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getTicketChatAction } from "store/actions/ticketAction";

const TicketChatShow = () => {
  const { id } = useParams();
  const location = useLocation();
  console.log("email", location.state);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-0">Chat </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="justify-content-center ">
                  <Col xs="12" md="10" className="shadow">
                    <Chatting ticketId={id} email={location?.state?.Email} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default TicketChatShow;

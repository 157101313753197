import React from "react";
import { Button, ButtonGroup } from "reactstrap";

const Pagination = ({ page, setPage, totalPages }) => {
  console.log("totalPages", totalPages);
  const lastPage = totalPages;
  const pagesToShow = 4;
  const generatePages = () => {
    const pages = [];
    if (lastPage <= pagesToShow) {
      for (let i = 1; i <= lastPage; i++) {
        pages.push(i);
      }
    } else {
      if (page <= pagesToShow - 1) {
        for (let i = 1; i <= pagesToShow; i++) {
          pages.push(i);
        }
      } else if (page >= lastPage - pagesToShow + 1) {
        for (let i = lastPage - pagesToShow + 1; i <= lastPage; i++) {
          pages.push(i);
        }
      } else {
        for (let i = page - 1; i <= page + 1; i++) {
          pages.push(i);
        }
      }
      if (pages[0] !== 1) {
        pages.unshift("...");
      }
      if (pages[pages.length - 1] !== lastPage) {
        pages.push("...");
      }
    }
    return pages;
  };
  return (
    <div>
      <nav aria-label="Page navigation">
        <div className="d-flex align-items-center justify-content-center gap-2 ">
          <div
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
            disabled={page === 1}
            className=" rounded-circle cursor-pointer"
          >
            <svg
              className="bg-grey border shadow rounded-circle cursor-pointer"
              style={{
                height: "30px",
                width: "30px",
              }}
              viewBox="0 0 20 20"
            >
              <path
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </div>
          {generatePages()?.map((pageNumber, index) => (
            <div
              key={index}
              onClick={() => {
                if (pageNumber !== "...") {
                  setPage(pageNumber);
                }
              }}
              className={`mx-2 rounded-circle cursor-pointer d-flex justify-content-center align-items-center ${
                page === pageNumber
                  ? " text-white bg-info "
                  : " text-black bg-gradient-white border "
              }`}
              style={{
                height: "30px",
                width: "30px",
              }}
            >
              {pageNumber}
            </div>
          ))}
          <div
            onClick={() => {
              if (page < lastPage) {
                setPage(page + 1);
              }
            }}
            disabled={page === lastPage}
            className=" text-primary rounded-circle cursor-pointer"
          >
            <svg
              className="bg-grey border shadow rounded-circle cursor-pointer"
              viewBox="0 0 20 20"
              style={{
                height: "30px",
                width: "30px",
              }}
            >
              <path
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default Pagination;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button } from "reactstrap";
import { allPublishNews, deleteNews } from "store/actions/newsAction";
import ModalMain from "./ModalMain";
import MarkDownRenderer from "./MarkDownRenderer";

const ViewPublishNews = ({ obj }) => {
  const dispatch = useDispatch();
  const { allPublishdata } = useSelector((state) => state.news);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState();
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpanded = (id) => {
    console.log("Toggling ID:", id);
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [id]: !prevExpandedItems[id],
    }));
  };
  const openModal = (Id) => {
    setId(Id);
    setIsModalOpen(!isModalOpen);
  };

  const deleteItem = () => {
    dispatch(
      deleteNews(id, () => {
        openModal();
        dispatch(allPublishNews());
      })
    );
  };

  useEffect(() => {
    dispatch(allPublishNews());
  }, [dispatch]);

  const ParagraphCard = ({}) => {
    // const [isExpanded, setIsExpanded] = useState(false);
    // const text = obj?.News?.AIResponse || "";

    // const toggleText = () => {
    //   setIsExpanded(!isExpanded);
    // };

    return (
      <>
        {/* {isExpanded ? ( */}
        <>
          {/* <span dangerouslySetInnerHTML={{ __html: text }}></span> */}
          {/* <div className='text_after_btn'> */}

          {/* {!obj?.Published && (
                <Button className="bg-success text-white hover:cursor-pointer all_btns">
                  Publish
                </Button>
              )} */}
          {/* <Button className="bg-danger text-white cursor-pointer" onClick={() => openModal(obj.Id)}>
                Delete
              </Button>
              <Button
                onClick={toggleText}
                className='btn bg-success text-white hover:cursor-pointer'
              >
                Show Less
              </Button> */}
          {/* </div> */}
        </>
        {/* ) : ( */}
        <>
          {/* {text.length > 50 ? <span dangerouslySetInnerHTML={{ __html: text.slice(0, 225) }}></span> : <span dangerouslySetInnerHTML={{ __html: text }}></span>} */}
          {/* {text.length > 150 && (
              <div className='btn_of_api'>
                <Button className="bg-danger text-white cursor-pointer" onClick={() => openModal(obj.Id)}>
                                Delete
                            </Button>
                <Button
                  onClick={toggleText}
                  className='btn bg-success text-white hover:cursor-pointer'
                >
                  Show More
                </Button>

              </div>
            )} */}
        </>
        {/* )} */}
      </>
    );
  };
  const getDesc = (text, isExpanded) => {
    const displayText = (id) => {
      if (expandedItems[id]) {
        return text;
      } else {
        // Display only the first 50 characters when not expanded
        return text.slice(0, 50) + (text.length > 50 ? "..." : "");
      }
    };

    if (isExpanded) {
      return text;
    } else {
      return text.slice(0, 225) + "...";
    }
  };
  return (
    <div>
      <>
        <Row>
          {allPublishdata?.length > 0 &&
            allPublishdata
              ?.slice()
              ?.sort((a, b) => b.Id - a.Id)
              .map((obj) => {
                const text = obj?.News?.AIResponse || "";
                const isExpanded = expandedItems[obj.Id];
                const showMoreText = isExpanded ? text : text.slice(0, 200);

                return (
                  <Col key={obj.id} md="6" lg="4">
                    <div className="card_container">
                      <div
                        className={`card_text ${
                          isExpanded ? "show-scrollbar" : ""
                        }`}
                      >
                        <ParagraphCard obj={obj} />

                        <MarkDownRenderer text={showMoreText} />
                      </div>
                      <div className="btn_all">
                        <Button
                          onClick={() => toggleExpanded(obj.Id)}
                          className="btn bg-success text-white hover:cursor-pointer"
                        >
                          {isExpanded ? "Show Less" : "Show More"}
                        </Button>
                        <Button
                          className="bg-danger text-white cursor-pointer"
                          onClick={() => openModal(obj.Id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
        </Row>
      </>
      <ModalMain
        isOpen={isModalOpen}
        openModal={openModal}
        deleteItem={deleteItem}
        id={id}
      />
    </div>
  );
};

export default ViewPublishNews;

import { WAIT_LIST } from "store/types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { WAIT_LIST_LOADER } from "store/types";
import { TOTAL_DASHBOARD } from "store/types";
import { STATUS_LOADER } from "store/types";

var waitlist = RepositoryFactory.get("waitList");

export const setWaitListLoading = (val) => async (dispatch) => {
  dispatch({ type: WAIT_LIST_LOADER, payload: val });
};
export const setStatusLoading = (val) => async (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: val });
};

export const getWaitListAction = () => async (dispatch) => {
  try {
    await dispatch(setWaitListLoading(true));
    const { data } = await waitlist.getWaitListRep();
    console.log("data w", data);
    if (data?.Detail?.Data?.length > 0) {
      dispatch({ type: WAIT_LIST, payload: data?.Detail?.Data });
      await dispatch(setWaitListLoading(false));
    } else {
      dispatch({ type: WAIT_LIST, payload: [] });
      await dispatch(setWaitListLoading(false));
    }
  } catch (error) {
    console.error("Error logging out:", error?.message);
    await dispatch(setWaitListLoading(false));
  }
};
export const getDashBoardTotalAction = () => async (dispatch) => {
  try {
    const { data } = await waitlist.getTotalRep();
    console.log("d", data);
    if (data?.Data) {
      dispatch({ type: TOTAL_DASHBOARD, payload: data?.Data });
    } else {
      dispatch({ type: TOTAL_DASHBOARD, payload: data?.Data });
    }
  } catch (error) {
    console.error("Error logging out:", error?.message);
  }
};
export const deleteWaitListAction = (id, onSuccess) => async (dispatch) => {
  try {
    const { data } = await waitlist.deleteWaitListRep(id);
    console.log("delete", data);
    if (data) {
      onSuccess();
    }
    // if (data?.Detail?.Data?.length > 0) {
    //   dispatch({ type: WAIT_LIST, payload: data?.Detail?.Data });
    // }
  } catch (error) {
    console.error("Error logging out:", error?.message);
  }
};
export const changeWaitListAction =
  (payload, onSuccess) => async (dispatch) => {
    try {
      await dispatch(setStatusLoading(true));
      const { data } = await waitlist.changeStatusRep(payload);
      if (data?.Detail) {
        alert(data?.Detail);
        onSuccess();
        await dispatch(setStatusLoading(false));
      } else {
        alert(data?.Detail);
        await dispatch(setStatusLoading(false));
      }
    } catch (error) {
      alert(error.message);
      await dispatch(setStatusLoading(false));
      console.error("Error logging out:", error?.message);
    }
  };

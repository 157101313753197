import Index from "views/Index.js";
import Profile from "views/Profile.js";
import Maps from "views/Maps.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Tables from "views/Tables.js";
import Icons from "views/Icons.js";
import WaitList from "views/WaitList";
import Subscription from "views/Subscription";
import ActivateUsers from "views/ActivateUsers";
import AdminUser from "views/AdminUser";
import Tickets from "views/Tickets";
import AdminTicket from "views/AdminTicket";
import ChatHistory from "views/ChatHistory";
import CardComponent from "components/CardComponent";
import Blogs from "views/Blogs";
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/super_admin",
  },

  {
    path: "/wait-list",
    name: "WaitList User",
    icon: "ni ni-tv-2 text-primary",
    component: WaitList,
    layout: "/super_admin",
  },
  {
    path: "/subscription",
    name: "Subscription",
    icon: "ni ni-tv-2 text-primary",
    component: Subscription,
    layout: "/super_admin",
  },
  {
    path: "/activate-user",
    name: "Activate User",
    icon: "ni ni-tv-2 text-primary",
    component: ActivateUsers,
    layout: "/super_admin",
  },
  {
    path: "/admin-users",
    name: "Admins User",
    icon: "ni ni-tv-2 text-primary",
    component: AdminUser,
    layout: "/super_admin",
  },
  {
    path: "/assign-tickets",
    name: "Assign Ticket",
    icon: "ni ni-tv-2 text-primary",
    component: Tickets,
    layout: "/super_admin",
  },
  {
    path: "/news",
    name: "News",
    icon: "ni ni-tv-2 text-primary",
    component: Blogs,
    layout: "/super_admin",
  },
  {
    path: "/support-ticket",
    name: "Support And Ticket",
    icon: "ni ni-tv-2 text-primary",
    component: AdminTicket,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
];
export default routes;
